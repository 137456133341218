import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const { id, heading, options, selectedOptions } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }
  const updatedHeading = heading === "Features and Amenities" ? 'Features & Amenities' : heading;
  
  return (
    <div id={id} className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {updatedHeading}:
      </Heading>
      <PropertyGroup
        id={`ListingPage.${id}`}
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;
